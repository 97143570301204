$primaryColor: #0064e0;
$secondaryColor: #499eff;
$lightColor: #2ab7eba1;
$backgroundColor: #ecf0f1;
$textColor: #1b3d5e;
$navHeight: 80px;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sacramento&display=swap');

html,body,h1,h2,h3,h4,h5,h6,b,span,div,input,p {
  font-family: "Poppins", sans-serif;
}

.screen {
  height: 90vh;
  padding: 0px 15px
}

// .pagesTop{
//   margin-top: 10px;
// }

.bg-light-color{
  background-color: $lightColor;
}

.text-primary{
  color: $secondaryColor !important;
}

.btn-primary {
  background-color: $primaryColor;
  border:none;
}



