/* src/Navbar.css */
.content-area {
    padding-bottom: 60px; /* Make space for the fixed bottom nav */
  }
  
  .nav-buttons {
    padding: 0.5rem 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }

.custom-navbar .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .offcanvas-menu {
    position: fixed;
    top: 0;
    right: -300px;
    width: 250px;
    height: 100%;
    background-color: #343a40;
    transition: right 0.3s ease-in-out;
    padding: 1rem;
    z-index: 1045;
  }
  
  .offcanvas-menu.active {
    right: 0;
  }
  
  .offcanvas-menu .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: white;
  }
  
  .offcanvas-menu .nav-link {
    color: white;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
  }
  
  .overlay.active {
    opacity: 1;
    visibility: visible;
  }
  
  @media (min-width: 992px) {
    .offcanvas-menu {
      display: none;
    }
  }
  