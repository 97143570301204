.offcanvas ul {
  margin: 0;
  padding: 0;
}

.offcanvas a {
  text-decoration: none;
  color: #000;
  padding: 10px 0;
  display: block;
  font-weight: 500;
}

.offcanvas a:hover {
  text-decoration: none;
  color: #007bff;
}

.offcanvas ul ul {
  padding-left: 15px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.offcanvas ul ul.collapse {
  max-height: 0;
}

.offcanvas ul ul.show {
  max-height: 500px; /* Adjust based on the content */
}

.offcanvas ul ul ul {
  padding-left: 15px;
}

.offcanvas a span {
  font-size: 1.2rem;
}
