.offcanvas {
    background-color: #f8f9fa;
  }
  
  .cart-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .cart-item h6 {
    margin: 0;
  }
  
  .cart-item p {
    margin: 0;
  }
  